import './App.css';

function App() {
  return (
    <div>
      <div>
        <div
          className="font-face-gm rtl bg-gradient header-container "
          style={{ direction: 'rtl' }}
        >
          <div className="rtl container" style={{ padding: 9 }}>
            <div className="rtl row">
              <div className="col-md-8 col-6">
                <ul className="header__menu">
                  <img
                    className="logo"
                    src="assets/image/footer-logo.png"
                    alt="logo"
                    style={{ height: '4rem', margin: 0, padding: 0 }}
                  />
                </ul>
                <div className="header">
                  {/* <div id="primary-menu-trigger">
                    <div id="mainMenu-trigger">
                      <div className="header-hamburger-btn hidden-lg-up ">
                        <a
                          href="#espritmenu"
                          className="mburger mburger--collapse"
                        >
                          <b />
                          <b />
                          <b />
                        </a>
                      </div>
                    </div>
                  </div> */}
                  {/* <nav className="es-navbar es-navbar-default" id="espritmenu">
                    <ul>
                      <li>
                        <a href="#">خانه</a>
                      </li>
                      <li>
                        <a href="#">درباره ما</a>
                      </li>
                      <li>
                        <a href="#">تماس با ما</a>
                      </li>
                    </ul>
                  </nav> */}
                </div>
              </div>
              <div className="col-md-4 col-6 header__button">
                <a
                  href="https://www.google.com/maps/place/Sharif+Innovation+Station"
                  className="button item"
                >
                  <span className="button__line" />
                  <span className="button__line" />
                  <span className="button__line" />
                  <span className="button__line" /> مدیریت دارایی
                </a>
              </div>
            </div>
          </div>
        </div>
        <article
          className="page-one bg-gradient "
          style={{ direction: 'rtl', height: '87vh' }}
        >
          <div className="container">
            <div className="row r-top-order">
              <div
                className="col-md-6 col-12 box-content"
                style={{ direction: 'rtl', textAlign: 'right' }}
              >
                <img className="logo" src="assets/image/logo.png" alt="logo" />
                <h4
                  text="right"
                  style={{
                    direction: 'rtl',
                    alignContent: 'rtl',
                    textAlign: 'right',
                  }}
                >
                  پلتفرم هوشمند جامعه محور پیشنهاد و برنامه ریزی مسیر تردد
                  روزانه در شهر دوستدار خانواده؛
                  <br />
                  مبتنی بر هوش مصنوعی غیرمتمرکز
                </h4>
              </div>

              <div
                className="col-md-6 col-12 page-one__pic"
                style={{ display: 'flex', flexDirection: 'column' }}
              >
                <img
                  style={{ maxWidth: 'fit-content', borderRadius: 20 }}
                  src="assets/video/pol.gif"
                  alt="proof of location"
                />
                <p
                  text="right"
                  style={{
                    direction: 'rtl',
                    alignContent: 'rtl',
                    textAlign: 'center',
                    fontSize: '.9rem',
                    marginTop: '2rem',
                  }}
                >
                  Proof of location؛ تأیید جمع‌سپاری نقاط و اماکن با سطح سازگاری
                  بالا در شهر دوستدار خانواده است.
                </p>
              </div>
              {/* <div className='col-md-12'>
                  <a
                    className="o-btn btn-style"
                    href="https://www.google.com/maps/place/Sharif+Innovation+Station"
                    style={{ direction: 'rtl' }}
                  >
                    افزودن مکان دوستدار خانواده
                  </a>
                </div> */}
            </div>
          </div>
        </article>
        <section style={{ direction: 'rtl' }}>
          <div className="container">
            <div className="row r-top-order">
              <div
                className="col-md-6 col-12 box-content"
                style={{ direction: 'rtl', textAlign: 'right', marginTop: 3 }}
              >
                <h3
                  text="right"
                  className="h2-title"
                  style={{
                    direction: 'rtl',
                    alignContent: 'rtl',
                    textAlign: 'right',
                    margin: '1rem 0 ',
                  }}
                >
                  گواهی سطح سازگاری
                </h3>
                <p className="coin__text" style={{ textAlign: 'right' }}>
                  گواهی سطح سازگاری یک سند معتبر، نشان دهنده سطح سازگاری محیطی و
                  اجتماعی اماکن و مسیرهای تردد برای دسترسی عادلانه به خدمات شهری
                </p>
              </div>
              <div
                className="col-md-6 col-12 page-one__pic"
                style={{
                  textAlign: 'left',
                  direction: 'ltr',
                  flex: 1,
                  flexDirection: 'column',
                }}
              >
                <img
                  className="banner-img"
                  src={require('./video/Certificate.gif')}
                  alt="certificate"
                />
                <p
                  className="coin__text"
                  style={{ textAlign: 'right', fontSize: '0.9rem' }}
                >
                  گواهی سطح سازگاری یک سند معتبر، نشان دهنده سطح سازگاری محیطی و
                  اجتماعی اماکن و مسیرهای تردد برای دسترسی عادلانه به خدمات شهری
                </p>
              </div>
            </div>
          </div>
        </section>
        <section style={{ direction: 'rtl' }}>
          <div className="container" style={{ marginTop: '5rem' }}>
            <div className="row r-top-order">
              <div
                className="col-md-8 col-12 page-one__pic"
                style={{ textAlign: 'left', direction: 'ltr' }}
              >
                <img
                  style={{ width: '83%' }}
                  className="banner-img"
                  src="assets/image/sazgari.png"
                  alt="certificate"
                />
              </div>
              <div
                className="col-md-4 col-12 box-content"
                style={{ direction: 'rtl', textAlign: 'right' }}
              >
                <h3
                  className="h2-title"
                  text="right"
                  style={{
                    direction: 'rtl',
                    alignContent: 'rtl',
                    textAlign: 'right',
                  }}
                >
                  گواهی جبران، گواهی اعتبار و توکن سازگاری
                </h3>
                <p className="coin__text" style={{ textAlign: 'right' }}>
                  یک پروژه یا فعالیت، سطح سازگاری محیطی و اجتماعی عادلانه ای
                  برای دسترسی به خدمات شهری ارائه می دهد؛امتیازی تحت گواهی
                  "امتیاز سازگاری" به آن تخصیص داده می شود.
                </p>
                <p className="coin__text" style={{ textAlign: 'right' }}>
                  توکن سازگاری؛ میزان امتیاز ارزش گذاری شده " گواهی سطح سازگاری
                  " بوده، که با تبدیل امتیاز به تعداد واحدهای مشخصی از سهام
                  دیجیتال ایجاد می شود. این سهام دیجیتال قابل عرضه در بازار
                  اولیه خصوصی به متقاضیان می باشد.
                </p>
                <section style={{ textAlign: 'left' }}>
                  <a
                    className="o-btn btn-style"
                    href="https://www.google.com/maps/place/Sharif+Innovation+Station"
                    style={{ direction: 'rtl' }}
                  >
                    تبدیل امتیاز به توکن سازگاری
                  </a>
                </section>
              </div>
            </div>
          </div>
        </section>
        <section>
          <div className="container">
            <div className="row ">
              <div className="col-lg-8 col-12 blockchain-video__container">
                <video autoPlay loop muted>
                  <source src="assets/video/chart.mp4" type="video/mp4" />
                </video>
              </div>
              <div
                style={{ marginTop: '7rem' }}
                className="col-lg-4 col-12 coin__content "
              >
                <h3
                  text="right"
                  className="h2-title"
                  style={{
                    direction: 'rtl',
                    alignContent: 'rtl',
                    textAlign: 'right',
                  }}
                >
                  عرضه اولیه و نقدینگی در بازار ثانویه
                </h3>
                <p className="coin__text" style={{ textAlign: 'right' }}>
                  عرضه توکن های سازگاری در بازار اولیه خصوصی سبب تبادل این ابزار
                  مالی در زنجیره اقتصادی شده و مازاد آن در بازار ثانویه برای
                  افزایش نقدینگی و تکمیل زنجیره ارزش اقتصادی جهت داد و ستد عرضه
                  می شود. این روش سبب افزایش نقدینگی توکن های سازگاری با ورود
                  سرمایه های خرد و غیرمولد، در بازار می شود.
                </p>
              </div>
            </div>
          </div>
        </section>
        <section>
          <div className="container">
            <div className="row " style={{ marginTop: '7rem' }}>
              <div
                className="col-lg-6 col-12 coin__content"
                style={{
                  justifyContent: 'space-between',
                  flex: 1,
                  display: 'flex',
                  flexDirection: 'column',
                }}
              >
                <h3 className="h2-title" style={{ textAlign: 'right' }}>
                  مشارکت همگانی در ایجاد اماکن با سطح سازگاری بالا، ثبت اطلاعات
                  و صحت سنجی اجتماع محور جزئیات آن در شهر دوستدار خانواده
                </h3>
                <section style={{ margin: '0 26%' }}>
                  <a
                    className="o-btn btn-style"
                    href="https://www.google.com/maps/place/Sharif+Innovation+Station"
                    style={{ direction: 'rtl', width: 'fit-content' }}
                  >
                    افزودن مکان دوستدار خانواده
                  </a>
                </section>
              </div>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'end',
                }}
                className="col-lg-6 col-12 blockchain-video__container"
              >
                <video
                  autoPlay
                  loop
                  muted
                  style={{ position: 'relative', width: '40%' }}
                >
                  <source src="assets/video/rp.mp4" type="video/mp4" />
                </video>
                <p
                  text="right"
                  style={{
                    direction: 'rtl',
                    alignContent: 'rtl',
                    textAlign: 'right',
                    fontSize: '0.9rem',
                  }}
                >
                  داشتن انگیزه توسعه پایدار جامعه محور معماری شهری با " خلق ارزش
                  اشتراکی " در شبکه ذینفعان ( توزیع توکن سازگاری در بین مشارکت
                  کنندگان )
                </p>
              </div>
            </div>
          </div>
        </section>
        <section className="page-two box">
          <div className="container " style={{ marginTop: '8rem' }}>
            <div className="row">
              <div className="col-12">
                <h3 className="h2-title">
                  ویژگی پلتفرم مدیریت شهر دوستدار خانواده
                </h3>
              </div>
              <div className="  col-lg-3 col-sm-6 col-12 focuse__item ">
                <img className="item-img" src="assets/image/1 (1).png" />
                <p className="item-text">
                  - تایید و صحت سنجی اجتماع محور اماکن دوستدار خانواده
                </p>
              </div>
              <div className=" col-lg-3 col-sm-6 col-12 focuse__item ">
                <img className="item-img" src="assets/image/2.png" />
                <p className="item-text">
                  تسریع در توسعه فراگیر و مشارکتی معماری شهری
                </p>
              </div>
              <div className="  col-lg-3 col-sm-6 col-12 focuse__item ">
                <img className="item-img" src="assets/image/3.png" />
                <p className="item-text">
                  خلق زنجیره ارزش اقتصادی نوین در جوامع شهری
                </p>
              </div>
              <div className=" col-lg-3 col-sm-6 col-12 focuse__item ">
                <img className="item-img" src="assets/image/4.png" />
                <p className="item-text">
                  درگاه یکپارچه مشارکتهای اجتماعی شهری در جوامع پایدار
                </p>
              </div>
            </div>
          </div>
        </section>

        <section className="page-four team box ">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <h3 className="h2-title">تیم ما</h3>
              </div>
              <div className="col-lg-3 col-sm-6 col-12 o-column">
                <img className="team__img" src="assets/image/bashiri.png" />
                <p className="team__lable">
                  Behboud BASHIRI
                  <br />
                  CEO
                </p>
              </div>
              <div className="col-lg-3 col-sm-6 col-12 o-column">
                <img className="team__img" src="assets/image/behzad.png" />
                <p className="team__lable">
                  Behzad CHAHARBAGHI
                  <br />
                  CTO
                </p>
              </div>
              <div className="col-lg-3 col-sm-6 col-12  o-column">
                <img className="team__img" src="assets/image/ghazale.png" />
                <p className="team__lable">
                  Zahra DAVARZANI
                  <br />
                  Agile Coach
                </p>
              </div>
              <div className="col-lg-3 col-sm-6 col-12 o-column">
                <img className="team__img" src="assets/image/darabi.jpg" />
                <p className="team__lable">
                  Amirhossein DARABI
                  <br />
                  CMO
                </p>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-3 col-sm-6 col-12 o-column">
                <img
                  className="team__img"
                  src="assets/image/Mojtaba SHAHVERDI.png"
                />
                <p className="team__lable">
                  Mojtaba SHAHVERDI
                  <br />
                  Frontend developer
                </p>
              </div>
              <div className="col-lg-3 col-sm-6 col-12 o-column">
                <img
                  className="team__img"
                  src="assets/image/Shina KETMAN.png"
                />
                <p className="team__lable">
                  Shina KETMAN
                  <br />
                  Ui designer
                </p>
              </div>
              <div className="col-lg-3 col-sm-6 col-12  o-column">
                <img className="team__img" src="assets/image/Arshia HADI.png" />
                <p className="team__lable">
                  Arshia HADI
                  <br />
                  Backend developer
                </p>
              </div>
              <div className="col-lg-3 col-sm-6 col-12 o-column">
                <img
                  className="team__img"
                  src="assets/image/Fatemeh SAFALO.png"
                />
                <p className="team__lable">
                  Fatemeh SAFALO
                  <br />
                  Contant writer
                </p>
              </div>
            </div>
          </div>
        </section>
        <section>
          <div className="container" style={{ marginTop: '5rem' }}>
            <div className="row">
              <div className="col-12">
                <h3 className="h2-title">دستاورد ها</h3>
              </div>
              <div className="col-lg-3 col-sm-6 col-12 o-column">
                <img className="team__img" src="assets/image/axeleo.jpg" />
                <p className="team__lable">Axeleo</p>
              </div>
              <div className="col-lg-3 col-sm-6 col-12 o-column">
                <img className="team__img" src="assets/image/onlylyon.jpg" />
                <p className="team__lable">Only Lyon</p>
              </div>
              <div className="col-lg-3 col-sm-6 col-12  o-column">
                <img className="team__img" src="assets/image/staionf.png" />
                <p className="team__lable">Station F</p>
              </div>
              <div className="col-lg-3 col-sm-6 col-12 o-column">
                <img className="team__img" src="assets/image/startup.png" />
                <p className="team__lable">Startup Prize</p>
              </div>
            </div>
          </div>
        </section>
        {/* <section className="page-five media box">
          <div className="container ">
            <div className="row">
              <div className="col-xl-12">
                <h3 className="h2-title">Media</h3>
              </div>
              <div className="col-lg-3 col-sm-6 col-12 media__item">
                <a href="#">
                  <img className="item-img" src="assets/image/media.png" />
                  <p className="media-text-title">This is a news title</p>
                  <p className="media-text">This is a news description</p>
                </a>
              </div>
              <div className="col-lg-3 col-sm-6 col-12 media__item">
                <a href="#">
                  <img className="item-img" src="assets/image/media.png" />
                  <p className="media-text-title">This is a news title</p>
                  <p className="media-text">This is a news description</p>
                </a>
              </div>
              <div className="col-lg-3 col-sm-6 col-12 media__item">
                <a href="#">
                  <img className="item-img" src="assets/image/media.png" />
                  <p className="media-text-title">This is a news title</p>
                  <p className="media-text">This is a news description</p>
                  <p className="media-text">This is a news description</p>
                </a>
              </div>
              <div className="col-lg-3 col-sm-6 col-12 media__item">
                <a href="#">
                  <img className="item-img" src="assets/image/media.png" />
                  <p className="media-text-title">This is a news title</p>
                  <p className="media-text">This is a news description</p>
                </a>
              </div>
            </div>
          </div>
        </section> */}
        <footer className="box bg-gradient">
          <div className="container">
            <div className="row">
              <div className="col-lg-4 col-md-6 col-12 ">
                <a href="#">
                  <img
                    className="footer-logo"
                    src="assets/image/footer-logo.png"
                    alt="logo"
                  />
                </a>
                <p className="logo-text" style={{ fontSize: '1em' }}>
                  پلتفرم هوشمند جامعه محور پیشنهاد و برنامه ریزی مسیر تردد
                  روزانه در شهر دوستدار خانواده
                  <br />
                  مبتنی بر هوش مصنوعی غیرمتمرکز
                </p>
                {/* <div className="social-media">
                  <a href>
                    <i className="icon-instagram" />
                  </a>
                  <a href>
                    <i className="icon-telegram" />
                  </a>
                  <a href>
                    <i className="icon-phone-call1" />
                  </a>
                  <a href>
                    <i className="icon-linkedin" />
                  </a>
                </div> */}
              </div>
              <div className="col-lg-4 col-md-6 col-12 ">
                <ul className="contact-us">
                  <li>
                    <p className="footer_title">تماس با ما</p>
                  </li>
                  <li>
                    <i className="icon-earth" />
                    تهران، خیابان آزادی، دانشگاه شریف، مرکز نوآوری هوش مصنوعی و
                    علوم داده
                  </li>
                  <li>
                    <i className="icon-phone-call" />
                    021-88982500
                  </li>
                  <li>
                    <i className="icon-email" />
                    denja@buta.link
                  </li>
                </ul>
              </div>
              <div className="col-lg-4 d-none d-lg-flex ">
                <img
                  style={{ height: 150, marginTop: 240 }}
                  className="footer-logo"
                  src="assets/image/sharif-logo.png"
                  alt="logo"
                />
                <img src="assets/image/love.png" />
              </div>
            </div>
          </div>
        </footer>
        <article className="container-fluid">
          <div className="row">
            <div className="col-12 copy-right">
              یک اثر اجتماعی از بوتا بلاکچین لب - 2023
            </div>
          </div>
        </article>
      </div>

      {/* 
<script src="assets/script/jquery-3.5.1.min.js"></script>
<script src="assets/script/bootstrap.min.js"></script>
<script src="assets/script/script.min.js"></script>
<script src="assets/script/script.js"></script>
<script>
    const button = document.querySelector('.button');

    button.addEventListener('mouseenter', (e) => {
        let x = e.clientX - e.target.offsetLeft;
        let y = e.clientY - e.target.offsetTop;

        const ripples = document.createElement('span');
        ripples.style.left = x + 'px';
        ripples.style.top = y + 'px';
        button.append(ripples);
        setTimeout(() => {
            ripples.remove()
        }, 1000);
    });



    function Vector2(x, y) {
        this.x = x;
        this.y = y;
    }

    function getRandomInt(min, max) {
        return Math.floor(Math.random() * (max - min + 1)) + min;
    }

    function Track(position) {
        this.position = position;

        this.draw = function(context) {
            context.beginPath();
            context.rect(this.position.x, this.position.y, 20, 20);
            context.fillStyle = '#ff7e1a';
            context.fill();
            context.lineWidth = 1;
            context.strokeStyle = '#111';
            context.stroke();
        }
    }
    var startTime;
    window.onload = function() {
        document.getElementById('myCanvas').width = document.getElementById('title').offsetWidth;
        document.getElementById('myCanvas').height = document.getElementById('title').offsetHeight;
        setTimeout(function() {
            startTime = (new Date()).getTime();
            animate(startTime);
        }, 1000);
    }
    var timer = 0;

    var totalLoopTime = 10000;
    window.requestAnimFrame = (function(callback) {
        return window.requestAnimationFrame || window.webkitRequestAnimationFrame || window.mozRequestAnimationFrame || window.oRequestAnimationFrame || window.msRequestAnimationFrame ||
            function(callback) {
                window.setTimeout(callback, 1000 / 60);
            };
    })();

    var path = [];
    var ttd = [];

    function animate(startTime) {
        if (ttd.length > 100)
            ttd.shift();
        var canvas = document.getElementById('myCanvas');
        var context = canvas.getContext('2d');

        // update
        timer = (new Date()).getTime() - startTime;
        if (path.length == 0)
            path = generateRandomPoints(canvas);
        else {
            var nexPoint = getNextCoordinates(startTime, totalLoopTime, path);
            ttd.push(new Track(nexPoint));
        }

        // clear
        context.clearRect(0, 0, canvas.width, canvas.height);

        // draw stuff
        for (var i = ttd.length - 1; i >= 0; i--) {
            ttd[i].draw(context);
        };
        // request new frame
        requestAnimFrame(function() {
            animate(startTime);
        });
    }





    function getTimer() {
        return timer;
    }

    function generateRandomPoints(context) {
        var resultingVector = [];
        for (var i = 0; i < 6; i++) {
            var x = getRandomInt(0, context.offsetWidth);
            var y = getRandomInt(0, context.offsetHeight);
            var currentPoint3D = new Vector2(x, y);
            resultingVector.push(currentPoint3D);
        }
        return resultingVector;
    }

    function getNextCoordinates(loopStartTime, totalLoopTime, path) {
        var resultingPoint = new Vector2();
        var passedTime = timer;
        //Total passed ratio
        var passedRatio = passedTime / totalLoopTime;
        var totalSegments = path.length / 3;

        var totalTimePerSegment = totalLoopTime / totalSegments;
        //So it can loop forever
        while (passedRatio > 1) {
            passedRatio -= 1;
        }
        //Let's find our current bezier curve segment number
        var currentSegment = Math.floor(passedRatio * totalSegments);
        var currentSegmentRatio = (passedTime - currentSegment * totalTimePerSegment) / totalTimePerSegment;
        //It can be optimized here
        while (currentSegmentRatio > 1) {
            currentSegmentRatio -= 1;
        }

        var startingIndex = currentSegment * 3;
        if (startingIndex == path.length)
            startingIndex = 0;
        //our four path vertices
        var point0 = path[startingIndex];
        var point1 = path[startingIndex + 1];
        var point2 = path[startingIndex + 2];

        //if it's a last segment, we need to "connect" to the first vertex
        if (startingIndex + 3 >= path.length) {
            var point3 = path[0];
        } else {
            point3 = path[startingIndex + 3];
        }
        //At last, we find our coordinates
        if (typeof point0 == "undefined") {
            console.log(startingIndex);
            console.log(loopStartTime);
            console.log(path);
        }
        var tempRatio = 1 - currentSegmentRatio;
        resultingPoint.x = tempRatio * tempRatio * tempRatio * point0.x + 3 * tempRatio * tempRatio * currentSegmentRatio * point1.x + 3 * tempRatio * currentSegmentRatio * currentSegmentRatio * point2.x + currentSegmentRatio * currentSegmentRatio * currentSegmentRatio * point3.x;
        resultingPoint.y = tempRatio * tempRatio * tempRatio * point0.y + 3 * tempRatio * tempRatio * currentSegmentRatio * point1.y + 3 * tempRatio * currentSegmentRatio * currentSegmentRatio * point2.y + currentSegmentRatio * currentSegmentRatio * currentSegmentRatio * point3.y;
        return resultingPoint;
    }
</script>
<script>
    if ($(document).width() < '991.98') {
        $('.mm-listview').removeClass('sm ');
        $('.mm-listview').removeClass('sm-blue');
        $('.mm-listview').removeClass('sm-rtl');
        $('.header__menu').hide();
    } else {
        $('#espritmenu').hide();
    }
</script> */}
    </div>
  );
}

export default App;
